/* eslint-disable no-mixed-spaces-and-tabs */
<template>
 <p>{{ time }}</p>
 <div class="timetable">
  <p class="mb-0">{{ currentEvent }}</p>
  <div class="progress">
   <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="{width: eventProgress+'%'}"></div>
  </div>
  <p class="mt-2 mb-0">{{dayText}}</p>
  <div class="progress">
   <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="{width: dayProgress+'%'}"></div>
  </div>
 </div>
</template>


<script>
 export default {
     name: 'Home',
     components: {
     },
     data() {
         return {
             time: Date(),
             timetable: {
                 1: [
                     {name:'UBP/UE',start: (7+0/60)*3600, end: (7+45/60)*3600},
                     {name:'UBP/UE',start: (7+50/60)*3600, end: (8+35/60)*3600},
                     {name:'ALG',start: (8+40/60)*3600, end: (9+25/60)*3600},
                     {name:'UE',start: (9+45/60)*3600, end: (10+30/60)*3600},
                     {name:'MA',start: (10+35/60)*3600, end: (11+20/60)*3600},
                     {name:'HRJ',start: (11+25/60)*3600, end: (12+10/60)*3600},
                     {name:'TZK',start: (12+15/60)*3600, end: (12+55/60)*3600},
                 ],
                 2: [
                     {name:'URM',start: (7+0/60)*3600, end: (7+45/60)*3600},
                     {name:'DGL/URM',start: (7+50/60)*3600, end: (8+35/60)*3600},
                     {name:'DGL/URM',start: (8+40/60)*3600, end: (9+25/60)*3600},
                     {name:'GEO',start: (9+45/60)*3600, end: (10+30/60)*3600},
                     {name:'POV',start: (10+35/60)*3600, end: (11+20/60)*3600},
                     {name:'POV',start: (11+25/60)*3600, end: (12+10/60)*3600},
                     {name:'SRZ',start: (12+15/60)*3600, end: (12+55/60)*3600},
                     {name:'/',start: (13+0/60)*3600, end: (13+40/60)*3600},
                 ],
                 3: [
                     {name:'FI',start: (7+0/60)*3600, end: (7+45/60)*3600},
                     {name:'FI',start: (7+50/60)*3600, end: (8+35/60)*3600},
                     {name:'TZK',start: (8+40/60)*3600, end: (9+25/60)*3600},
                     {name:'DGL',start: (9+45/60)*3600, end: (10+30/60)*3600},
                     {name:'MA',start: (10+35/60)*3600, end: (11+20/60)*3600},
                     {name:'OR',start: (11+25/60)*3600, end: (12+10/60)*3600},
                     {name:'OR',start: (12+15/60)*3600, end: (12+55/60)*3600},
                     {name:'/',start: (13+0/60)*3600, end: (13+40/60)*3600},
                 ],
                 4: [
                     {name:'POR/ALG',start: (7+0/60)*3600, end: (7+45/60)*3600},
                     {name:'POR/ALG',start: (7+50/60)*3600, end: (8+35/60)*3600},
                     {name:'POR/ALG',start: (8+40/60)*3600, end: (9+25/60)*3600},
                     {name:'POR/ALG',start: (9+45/60)*3600, end: (10+30/60)*3600},
                     {name:'EJ',start: (10+35/60)*3600, end: (11+20/60)*3600},
                     {name:'EJ',start: (11+25/60)*3600, end: (12+10/60)*3600},
                     {name:'VJ',start: (12+15/60)*3600, end: (12+55/60)*3600},
                     {name:'/',start: (13+0/60)*3600, end: (13+40/60)*3600},
                 ],
                 5: [
                     {name:'MA',start: (7+0/60)*3600, end: (7+45/60)*3600},
                     {name:'MA',start: (7+50/60)*3600, end: (8+35/60)*3600},
                     {name:'DGL',start: (8+40/60)*3600, end: (9+25/60)*3600},
                     {name:'HRJ',start: (9+45/60)*3600, end: (10+30/60)*3600},
                     {name:'HRJ',start: (10+35/60)*3600, end: (11+20/60)*3600},
                     {name:'TAL',start: (11+25/60)*3600, end: (12+10/60)*3600},
                     {name:'TAL',start: (12+15/60)*3600, end: (12+55/60)*3600},
                 ],
             },
             currentEvent: '/',
             eventProgress: 0,
             dayText: '/',
             dayProgress: 0,
         }
     },
     methods: {
         getCurrentEvent(){
             let now = new Date() - 6000
             let now_ts = (now/1000)%86400
             let timetable = this.timetable[now.getDay()]

             for (let i in timetable){ // Svaki element
                 i = parseInt(i)
                 if (timetable[i].start < now_ts && timetable[i].end > now_ts ){ // Unutar eventa
                     return {status: i,
                             current: timetable[i]}
                 } else if (parseInt(i)+1 < timetable.length){
                     if(timetable[i].end < now_ts && timetable[i+1].start > now_ts){ // Između eventa
                         return {status: -1,
                                 previous: timetable[i],
                                 next: timetable[i+1],
                                 start: timetable[i].end,
                                 end: timetable[i+1].start}
                     }
                 }
             }
         },
         getCurrentState(){
             let now = new Date()
             let now_ts = (now/1000)%86400
             let timetable = this.timetable[now.getDay()]

             if (!timetable){
                 return 0 // No events today
             } else if (now_ts < timetable[0].start){
                 return 1 // First event didn't start yet
             } else if (now_ts > timetable[timetable.length-1].end){
                 return 2 // All events ended
             } else {
                 return 3 // Still Has some events
             }
         },
         round(n, dec){
             return Math.floor(n * (10*dec)) / (10*dec)

         },
         formatSeconds(secs){
             let d = secs;
             let h = Math.floor(d / 3600);
             let m = Math.floor(d % 3600 / 60);
             let s = Math.floor(d % 3600 % 60);
             let out = ""
             
             if (h>0){
                 if (h < 10){
                     out += '0'
                 }
                 out += h.toString()+":"
             }
             if (m < 10){
                 out += '0'
             }
             out += m.toString() + ":"
             if (s < 10){
                 out += '0'
             }
             out += s.toString()
             return out
         },
         updateCurrentEvent(){
             let now = new Date()
             let now_ts = (now/1000)%86400
             let timetable = this.timetable[now.getDay()]

             if(this.getCurrentState() == 0){
                 this.currentEvent = "Slobodan dan"
                 this.eventProgress = '100'
                 this.dayText = "Slobodan dan"
                 this.dayProgress = '100'
             }
             if (this.getCurrentState() == 1){ // Prvi event nije jos poceo
                 this.currentEvent = 'Nastava jos nije pocela'
                 this.dayText = "Nastava jos nije pocela"
             } else if (this.getCurrentState() == 2){ // Prosao je zadnji event
                 this.currentEvent = 'Nastava je zavrsila'
                 this.dayText = "Nastava je zavrsila"
             } else { // Unutar 2 eventa
                 let current = this.getCurrentEvent()
                 let lengthDay = timetable[timetable.length-1].end-timetable[0].start
                 let passedDay = now_ts-timetable[0].start
                 this.dayProgress = ((passedDay/lengthDay)*100).toFixed(2).toString()
                 this.dayText = "Do kraja skolskog dana: " + this.formatSeconds(lengthDay-passedDay)
                 if (current.status == -1){ // Odmor
                     let length = current.end-current.start
                     let passed = now_ts-current.start
                     this.currentEvent = "Odmor" + " | "+ this.formatSeconds(length-passed) + " | Sljedece: " + current.next.name
                     this.eventProgress = ((passed/length)*100).toFixed(3).toString()
                 } else {
                     current = timetable[current.status]
                     let length = current.end-current.start
                     let passed = now_ts-current.start
                     this.currentEvent = current.name + "  "+ this.formatSeconds(length-passed)
                     this.eventProgress = ((passed/length)*100).toFixed(3).toString()
                 }
             }
         },
     },
     mounted(){
         this.dateInterval = setInterval(() => {this.time = new Date()}, 1000);
         this.nastavaInterval = setInterval(this.updateCurrentEvent, 1000);
         console.log('Mounted')
     },
     unmounted(){
         console.log('Unmounted')
         clearInterval(this.dateInterval)
         clearInterval(this.nastavaInterval)
     }
 }
</script>
